@font-face{
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Black.woff2') format("woff");
    font-weight:900;
    font-style: normal;
  }
  @font-face{
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Bold.woff') format("woff");
    font-weight:700;
    font-style: normal;
  }

  @font-face{
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Medium.woff') format("woff");
    font-weight:500;
    font-style: normal;
  }
  @font-face{
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Regular.woff') format("woff");
    font-weight:400;
    font-style: normal;
  } 

  @font-face{
    font-family: 'Nunito';
    src: url('./assets/fonts/Nunito-Regular.woff');
    font-weight:400;
    font-style: normal;
  } 
  @font-face{
    font-family: 'Londrina Solid';
    src: url('./assets/fonts/LondrinaSolid-Black.woff');
    font-weight:900;
    font-style: normal;
  } 